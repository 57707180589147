import { TextFieldFormsy } from '@fuse/core/formsy';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButton from 'app/shared/components/SubmitButton/SubmitButton';
import { setPassword } from 'app/shared/factories/user.factory'
import { showMessage } from 'app/store/fuse/messageSlice';
import { useHistory, useParams } from 'react-router';
import md5 from 'md5'

function SetPasswordForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  let { userId } = useParams();
  const [isFormValid, setIsFormValid] = useState(false);

  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (login.error) {
      setLoading(false);
    }
  }, [login]);

  function disableButton() {
    setIsFormValid(false);
  }

  function enableButton() {
    setIsFormValid(true);
  }

  function handleSubmit(model) {
    setLoading(true);
    
    setPassword(
      userId,
      {
      //oldPassword: md5(model.oldPassword.toLowerCase()),
      password: md5(model.password.toLowerCase()),
    })
      .then(() => {
        dispatch(showMessage({ message: 'Contraseña configurada exitosamente', variant: 'success' }));
        history.push('/login');
        setLoading(false)
      }).catch(()=> setLoading(false))
  }

  return (
    <div className='w-full'>
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className='flex flex-col justify-center w-full'
      >
        {/* <TextFieldFormsy
          size='small'
          className='mb-16'
          type='text'
          name='email'
          label='Correo'
          validations={{
            isEmail: true,
          }}
          validationErrors={{
            isEmail: 'Formato de correo incorrecto.',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  email
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        /> */}

        {/* <TextFieldFormsy
          size='small'
          className='mb-16'
          type='password'
          name='oldPassword'
          label='Contraseña anterior'
          validations={{
            isExisty: true,
          }}
          validationErrors={{
            isExisty: 'Contraseña requerida',
          }}
          InputProps={{
            type: 'password',
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  vpn_key
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        /> */}

        <TextFieldFormsy
          size='small'
          className='mb-16'
          type='password'
          name='password'
          label='Contraseña'
          validations='equalsField:password-confirm'
          validationErrors={{
            equalsField: 'Debe coincidir con la confirmación',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  vpn_key
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        <TextFieldFormsy
          size='small'
          className='mb-16'
          type='password'
          name='password-confirm'
          label='Confirmación de contraseña'
          validations='equalsField:password'
          validationErrors={{
            equalsField: 'Contraseñas no coinciden',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  vpn_key
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        <SubmitButton label='Configurar contraseña' loading={loading} disabled={!isFormValid} />
      </Formsy>
    </div>
  );
}

export default SetPasswordForm;
