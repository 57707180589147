import React from 'react';
import { Button, CircularProgress, withTheme } from '@material-ui/core';

export default withTheme(function SubmitButton({
  label,
  loading,
  disabled,
  theme,
  className,
  onClick,
  color = 'primary',
}) {
  return (
    <Button
      variant="contained"
      color={color}
      size="small"
      type="submit"
      disabled={disabled || loading}
      className={'normal-case ' + className}
      {...(onClick ? { onClick } : {})}
    >
      {label}
      {loading && (
        <CircularProgress
          className="ml-8"
          variant="indeterminate"
          thickness={2}
          size={16}
          style={{ color: theme.palette.getContrastText(theme.palette.primary.dark) }}
        />
      )}
    </Button>
  );
});
