import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.user': {
      '& .username, & .email': {
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
  },
  avatar: {
    width: 72,
    height: 72,
    position: 'absolute',
    top: 92,
    padding: 8,
    background: theme.palette.background.default,
    boxSizing: 'content-box',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

function UserNavbarHeader() {
  const classes = useStyles();
  const merchant = useSelector(({ merchant }) => merchant);
  const abbrName = merchant?.name.includes(' ') ? 
    merchant?.name
    .split(' ')
    .filter((e) => !(Number(e) || e === 'C.A.' || e === 'CA' || e === 'C.A'))
    .map((m, i) => (i < 3 ? m[0]?.toUpperCase() : ''))
    .join('') : merchant?.name[0].toUpperCase()

  return (
    <AppBar
      position='static'
      color='primary'
      elevation={0}
      classes={{ root: classes.root }}
      className='user relative flex flex-col items-center justify-center pt-12 pb-64 mb-32 z-0'
    >
      <Typography className='username text-16 max-w-224 text-center' color='inherit'>
        {merchant?.name}
      </Typography>
      <Typography className='email text-13 mt-8 opacity-50 whitespace-no-wrap' color='inherit'>
        {merchant?.idNumber}
      </Typography>
      <Avatar className={clsx(classes.avatar, 'avatar')} alt='user photo'>
        <Box
          className='flex items-center justify-center'
          borderRadius='50%'
          color='white'
          width='65px'
          height='65px'
          bgcolor='#192d3e'
        >
          <Typography variant='h5'>{abbrName}</Typography>
        </Box>
      </Avatar>
    </AppBar>
  );
}

export default UserNavbarHeader;
