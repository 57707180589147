import { TextFieldFormsy } from '@fuse/core/formsy';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitLogin } from 'app/auth/store/loginSlice';
import SubmitButton from 'app/shared/components/SubmitButton/SubmitButton';

function JWTLoginTab() {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);

  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (login.error) {
      setLoading(false);
    }
  }, [login]);

  function disableButton() {
    setIsFormValid(false);
  }

  function enableButton() {
    setIsFormValid(true);
  }

  function handleSubmit(model) {
    setLoading(true);
    dispatch(submitLogin(model));
  }

  return (
    <div className='w-full'>
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className='flex flex-col justify-center w-full'
      >
        <TextFieldFormsy
          className='mb-16'
          type='text'
          name='email'
          label='Correo'
          value=''
          validations={{
            isEmail: true,
          }}
          validationErrors={{
            isEmail: 'Formato de correo incorrecto.',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  email
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        <TextFieldFormsy
          className='mb-16'
          type='password'
          name='password'
          label='Contraseña'
          value=''
          validations={{
            isExisty: true,
          }}
          validationErrors={{
            isExisty: 'Contraseña requerida',
          }}
          InputProps={{
            className: 'pr-2',
            type: showPassword ? 'text' : 'password',
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  <Icon className='text-20' color='action'>
                    {showPassword ? 'visibility' : 'visibility_off'}
                  </Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        <SubmitButton label='Iniciar sesión' loading={loading} disabled={!isFormValid} />
      </Formsy>
    </div>
  );
}

export default JWTLoginTab;
