import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

export default function InfoHeader() {
  const payment = useSelector(({ payment }) => payment);

  const actualDate = Date.now();
  const nextPaymentDate = new Date(payment?.nextPaymentDate).getTime();

  const leftDays = (nextPaymentDate - actualDate) / 1000 / 60 / 60 / 24;

  if (leftDays < 3 && payment?.name !== 'free') {
    return (
      <Box className='bg-red-500 p-3 text-center'>
        <Typography className='text-white'> Recuerda pagar antes del {moment(nextPaymentDate).format('LL')}</Typography>
      </Box>
    );
  }

  return '';
}
