import React from 'react';
import NumberFormat from 'react-number-format';

export default function FormatAmount({ amount, suffix, className, ...others }) {
  return (
    <NumberFormat
      className={className}
      value={amount}
      displayType={'text'}
      thousandSeparator={'.'}
      decimalSeparator=','
      fixedDecimalScale={true}
      suffix={suffix || ' $'}
      decimalScale={2}
      {...others}
    />
  );
}
