import HTTP from '../services/HTTP.service';

export function update(postData) {
  return HTTP.put({ url: `/rates`, postData }).then(({ result }) => result);
}

export function getOfficalRates() {
  const apiEndpoint = 'https://pydolarve.org/api/v1';
  const headers = {
    authorization: 'Bearer 9DPIGuIdEaS-WkhCwF5LbQ',
  };
  return Promise.all([
    HTTP.get({ apiEndpoint, url: '/dollar?page=bcv', headers }),
    HTTP.get({ apiEndpoint, url: '/dollar?page=enparalelovzla', headers }),
  ]).then(([bcv, paralelo]) => {
    const fetchedRates = {
      bcv: bcv?.monitors?.usd?.price,
      paralelo: paralelo?.monitors?.enparalelovzla?.price,
    };

    return fetchedRates;
  });
}
