import React from 'react';

const SuppliersConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/store/debtors',
      exact: true,
      component: React.lazy(() => import('./Debtors')),
    },
    {
      path: '/store/debtors/:debtorId',
      component: React.lazy(() => import('./debtor/DebtorTransactions')),
    },
  ],
};

export default SuppliersConfig;
