import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';

import { setUserData, logoutUser } from './store/userSlice';

class Auth extends Component {
  state = {
    waitAuthCheck: false,
  };

  componentDidMount() {
    return Promise.all([
      // Comment the lines which you do not use
      //this.firebaseCheck(),
      //this.auth0Check(),
      //this.jwtCheck()
    ]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  render() {
    //console.log(this.state.waitAuthCheck);
    return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      showMessage,
      hideMessage,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
