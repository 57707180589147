import React from 'react';

const PricesControlConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/store/prices-control/products',
      component: React.lazy(() => import('./ProductsPrices')),
    },
    {
      path: '/store/prices-control/change-rates',
      component: React.lazy(() => import('./changeRates/ChangeRates')),
    },
    // {
    //   path: '/store/prices-control/orders/:orderId',
    //   component: React.lazy(() => import('./order/Order')),
    // },
    // {
    // 	path: '/apps/prices-control',
    // 	component: () => <Redirect to="/apps/prices-control/" />
    // }
  ],
};

export default PricesControlConfig;
