import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import es from './navigation-i18n/es';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('es', 'navigation', es);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'root',
    title: '',
    translate: '',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'prices-control',
        title: 'Control de Precios',
        type: 'item',
        url: '/store/prices-control/products',
        icon: 'fas fa-cube',
        exact: true,
      },
      {
        id: 'change-rates',
        title: 'Tasa de cambio',
        type: 'item',
        url: '/store/prices-control/change-rates',
        icon: 'fas fa-tags',
        exact: true,
      },
      {
        id: 'inventory',
        title: 'Inventario',
        translate: '',
        type: 'item',
        icon: 'fas fa-boxes',
        url: '/store/inventory',
      },
      {
        id: 'invoicing',
        title: 'Facturación',
        translate: '',
        type: 'collapse',
        icon: 'fas fa-receipt',
        url: '/store/invoicing/new',
        children: [
          {
            id: 'new-invoice',
            title: 'Nueva Factura',
            type: 'item',
            url: '/store/invoicing/new',
          },
          {
            id: 'invoices-list',
            title: 'Ver Facturas',
            type: 'item',
            url: '/store/invoicing/list',
          },
        ],
      },
      // {
      //   id: 'accounting',
      //   title: 'Contabilidad',
      //   translate: '',
      //   type: 'item',
      //   icon: 'fas fa-dollar-sign',
      //   url: '/store/accounting',
      // },
      {
        id: 'catalog',
        title: 'Catálogo al mayor',
        translate: '',
        type: 'item',
        icon: 'fas fa-book-open',
        url: '/store/catalog',
      },
      {
        id: 'suppliers',
        title: 'Pago Proveedores',
        translate: '',
        type: 'item',
        icon: 'fas fa-truck-loading',
        url: '/store/suppliers',
      },
      {
        id: 'debtors',
        title: 'Deudores',
        translate: '',
        type: 'item',
        icon: 'fas fa-hand-holding-usd',
        url: '/store/debtors',
      },
      {
        id: 'employees',
        title: 'Mis Empleados',
        translate: '',
        type: 'item',
        icon: 'fas fa-user',
        url: '/store/employees',
      },
      {
        id: 'help',
        title: 'Información',
        translate: '',
        type: 'item',
        icon: 'fas fa-info-circle',
        url: '/store/info',
      },
      {
        id: 'logout',
        title: 'Cerrar sesión',
        translate: '',
        type: 'item',
        icon: 'fas fa-sign-out-alt',
        url: '/logout',
        exact: true,
      },
    ],
  },
];

export default navigationConfig;
