import { createSlice } from '@reduxjs/toolkit';
import { setUserData } from './userSlice';
import md5 from 'md5';
import { auth } from 'app/shared/factories/user.factory';
import jwtParser from 'app/shared/helpers/jwtParser';

export const submitLogin = ({ email, password }) => async (dispatch) => {
  dispatch(loginSlice.actions.restore());
  return auth({ email: email.toLowerCase(), password: md5(password.toLowerCase()) })
    .then((user) => {
      localStorage.ADMINLITE_TOKEN = user.token;

      const parsed = jwtParser(user.token);
      dispatch(setUserData(parsed));

      return dispatch(loginSuccess());
    })
    .catch((error) => {
      return dispatch(loginError(error));
    });
};

const initialState = {
  success: false,
  error: false,
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    restore: (state) => {
      state.error = false;
    },
    loginSuccess: (state) => {
      state.success = true;
    },
    loginError: (state) => {
      state.success = false;
      state.error = true;
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
