import { createSlice } from '@reduxjs/toolkit';
import { createMerchant } from 'app/shared/factories/merchant.factory';
import md5 from 'md5';

export const submitRegister = (newMerchant) => async (dispatch) => {
  dispatch(registerSlice.actions.restore());
  return createMerchant({
    ...newMerchant,
    password: md5(newMerchant.password.toLowerCase()),
  })
    .then(() => {
      return dispatch(registerSuccess());
    })
    .catch((error) => {
      return dispatch(registerError(error));
    });
};

const initialState = {
  success: false,
  loading: false,
  error: false,
};

const registerSlice = createSlice({
  name: 'auth/register',
  initialState,
  reducers: {
    restore: () => ({ ...initialState }),
    registerSuccess: (state) => {
      state.success = true;
    },
    registerError: (state, action) => {
      state.success = false;
      state.error = action.payload;
    },
  },
  extraReducers: {},
});

export const { registerSuccess, registerError, restore } = registerSlice.actions;

export default registerSlice.reducer;
