import LandingConfig from './landing/LandingConfig';
import PricesControlConfig from './pricesControl/PricesControlConfig';
import InvoicingConfig from './invoicing/InvoicingConfig';
import SuppliersConfig from './suppliers/SuppliersConfig';
import LoginConfig from './login/LoginConfig';
import RegisterConfig from './register/RegisterConfig';
import DebtorsConfig from './debtors/DebtorsConfig';
import InformationConfig from './information/InformationConfig';
import InventoryConfig from './inventory/InventoryConfig';
// import AccountingConfig from './accounting/AccountingConfig';
import EmployeesConfig from './employees/EmployeesConfig';
import SetPasswordConfig from './setPassword/SetPasswordConfig';
import CatalogConfig from './catalog/CatalogConfig';
import ClientViewCatalogConfig from './clientViewCatalog/ClientViewCatalogConfig';

const mainConfigs = [
  LandingConfig,
  PricesControlConfig,
  InvoicingConfig,
  LoginConfig,
  RegisterConfig,
  SuppliersConfig,
  DebtorsConfig,
  InformationConfig,
  InventoryConfig,
  // AccountingConfig,
  EmployeesConfig,
  SetPasswordConfig,
  CatalogConfig,
  ClientViewCatalogConfig,
];

export default mainConfigs;
