import { useTheme, ThemeProvider } from '@material-ui/core/styles';
import { selectContrastMainTheme } from 'app/store/fuse/settingsSlice';
import React from 'react';
import { useSelector } from 'react-redux';

function FusePageCardedHeader(props) {
  const theme = useTheme();
  const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.main));

  return (
    <div className={props.classes.header}>
      {props.header && (
        <ThemeProvider theme={contrastTheme} >
          {props.header}
          {props.subHeader && <div className={props.classes.subHeader}>{props.subHeader}</div>}
        </ThemeProvider>
      )}
    </div>
  );
}

export default FusePageCardedHeader;
