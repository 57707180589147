import { Button } from '@material-ui/core';
import React from 'react';
import NotFound from '../NotFound/NotFound';
import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    function restart() {
      localStorage.removeItem('ADMINLITE_TOKEN');
      window.location.reload();
    }

    if (this.state.errorInfo) {
      const error = this.state.error ? this.state : 'Unknown error';
      Sentry.captureException(error);
      // Error path
      return (
        <div className="flex flex-col justify-center items-center w-full bg-white">
          <NotFound
            title={`Pedimos disculpas, ha ocurrido un error inesperado`}
            message="Por favor inicia sesión nuevamente"
          />
          <Button variant="outlined" onClick={restart} className="normal-case">
            Iniciar sesión
          </Button>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
