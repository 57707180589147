import { Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

function DataTableHead(props) {
  return (
    <TableHead>
      <TableRow className='h-64'>
        {props.columns.map((row, i) => {
          return (
            <TableCell className='md:p-16' key={i} align={row.align} padding={row.disablePadding ? 'none' : 'default'} size='medium'>
              <Typography> {row.label}</Typography>
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}

export default DataTableHead;
