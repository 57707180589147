export function sortByString(arr, field) {
  arr.sort(function (a, b) {
    if (a[field] > b[field]) {
      return 1;
    }
    if (a[field] < b[field]) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  return arr;
}
