import React from 'react';

const InvoicingConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/store/invoicing/new',
      component: React.lazy(() => import('./Invoicing')),
    },
    {
      path: '/store/invoicing/list',
      component: React.lazy(() => import('./invoicesList/InvoicesList')),
    },
  ],
};

export default InvoicingConfig;
