import React from 'react';

const InformationConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/store/info',
      component: React.lazy(() => import('./Information')),
    },
  ],
};

export default InformationConfig;
