import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'app/auth/store/userSlice';

const allowedRoutes = ['login', 'register', 'set-password', 'client-view-catalog']
export default function useLocationWatcher() {
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allowedRoutes.includes(location.pathname.split('/').pop()) && !localStorage.ADMINLITE_TOKEN) {
    window.location = '/login';
    }

    if(location.pathname === '/logout') {
      dispatch(logoutUser())
    }

  }, [location, dispatch]);
}
