import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { navbarToggleMobile } from 'app/store/fuse/navbarSlice';
import AppContext from 'app/AppContext';
import { useHistory, useLocation } from 'react-router';
import { matchRoutes } from 'react-router-config';
import AppIcon from 'app/shared/components/AppIcon/AppIcon';

const pathsWithBackButton = ['/store/suppliers/:supplierId', '/store/debtors/:debtorId'];
function NavbarMobileToggleButton(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const location = useLocation();
  const { pathname } = location;
  const matched = matchRoutes(routes, pathname)[0];

  function findIfBack() {
    return pathsWithBackButton.find((e) => matched.match.path === e);
  }

  function handleClick() {
    if (findIfBack()) {
      history.goBack();
    } else {
      dispatch(navbarToggleMobile());
    }
  }

  if (findIfBack()) {
    return (
      <IconButton className={props.className} onClick={handleClick} color='inherit' disableRipple>
        <AppIcon fa='fas fa-chevron-left' faSize='18px' />
      </IconButton>
    );
  }

  return (
    <IconButton className={props.className} onClick={handleClick} color='inherit' disableRipple>
      {props.children}
    </IconButton>
  );
}

NavbarMobileToggleButton.defaultProps = {
  children: <Icon color='action'>menu</Icon>,
};

export default NavbarMobileToggleButton;
