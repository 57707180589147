import React from 'react';
import { Box, Typography } from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';

function ExpiredInvoice() {
  return (
    <div className='flex flex-col flex-1 items-center justify-center p-16'>
      <div className='max-w-512 text-center'>
        <FuseAnimate animation='transition.expandIn' delay={100}>
          <Typography variant='h4' color='primary' className='font-medium mb-16'>
            Tu factura se encuentra vencida.
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <>
            <Typography variant='h6' color='textSecondary' className='mb-16 text-18'>
              Si ya cancelaste, reporta el pago vía WhatsApp haciendo
              <a href='https://wa.me/584126397780'> click aquí</a>
            </Typography>
            <Box className='rounded-4 border-1 p-10 max-w-288 m-auto'>
              <Typography className='text-16 font-600'>Datos pago móvil</Typography>
              <Typography>Bancaribe (0114)</Typography>
              <Typography>Cédula: V-22.520.812</Typography>
              <Typography>Tlf: 0414-2845339</Typography>
            </Box>
          </>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default ExpiredInvoice;
