import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import { Typography } from '@material-ui/core';

function FooterLayout2(props) {
  const footerTheme = useSelector(selectFooterTheme);

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id='fuse-footer'
        className='relative z-10'
        color='default'
        style={{ backgroundColor: footerTheme.palette.background.paper }}
        elevation={2}
      >
        <Toolbar className='min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex items-center overflow-x-auto'>
          <Typography>Copyright 2020</Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default React.memo(FooterLayout2);
