import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import fuse from './fuse';
import i18n from './i18nSlice';
import mainReducers from '../main/store';

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    fuse,
    i18n,
    ...mainReducers,
    ...asyncReducers,
  });

export default createReducer;
