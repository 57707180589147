/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import EventEmitter from '../services/EventEmitter.service';
import { useTranslation } from 'react-i18next';
import { showMessage } from '../../store/fuse/messageSlice';
import { setPaymentPlan } from 'app/main/store/slices/payment.slice';

export default function useHttpErrorHandler() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    EventEmitter.on('httpError', (errorResponse) => {
      console.log('error http capturado', errorResponse, errorResponse.response, errorResponse.message);
      let msg = {};

      if (errorResponse?.response?.data?.message) {
        msg = { title: 'Error', message: errorResponse?.response?.data?.message };
      } else if (errorResponse.message === 'Network Error') {
        msg = { title: 'Error de red', message: 'Parece que tienes problemas de conexión.' };
      } else {
        msg = { title: t('HTTP_ERROR_TITLE'), message: t('HTTP_ERROR_MSG') };
      }

      dispatch(showMessage({ ...msg, variant: 'error' }));
    });

    EventEmitter.on('setUserPlan', (userPlan) => {
      if(!userPlan) return
      const actualDate = Date.now();
      const nextPaymentDate = new Date(userPlan.nextPaymentDate).getTime();

      console.log({
        ...userPlan,
        status: userPlan.name !== 'free' && actualDate > nextPaymentDate ? 'expired' : 'active',
      })
      dispatch(
        setPaymentPlan({
          ...userPlan,
          status: userPlan.name !== 'free' && actualDate > nextPaymentDate ? 'expired' : 'active',
        })
      );
    });
  }, []);
}
