/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import FusePageCarded from '@fuse/core/FusePageCarded';
import PageHeader from 'app/fuse-layouts/layout1/components/PageHeader';
import DataTable from 'app/shared/components/DataTable/DataTable';
import FormatAmount from 'app/shared/components/FormatAmount/FormatAmount';
import NotFound from 'app/shared/components/NotFound/NotFound';
import * as crudFactory from 'app/shared/factories/crud.factory';
import useIsMountedRef from 'app/shared/hooks/useIsMountedRef';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router';

const columns = [
  {
    field: 'name',
    align: 'left',
    label: 'Nombre',
  },
  {
    field: 'unitsForPackage',
    align: 'left',
    label: 'Unidades por paquete',
  },
  {
    field: 'priceToSellUsd',
    align: 'left',
    disablePadding: false,
    label: 'Precio venta($)',
    render: ({ priceToSellUsd }) => {
      return <FormatAmount amount={priceToSellUsd} suffix={' $'} />;
    },
  },
  {
    field: 'priceToSellBs',
    align: 'left',
    disablePadding: false,
    label: 'Precio venta(Bs)',
    render: ({ rate, priceToSellUsd }) => {
      return <FormatAmount amount={priceToSellUsd * rate} suffix={' Bs'} />;
    },
  },
];

function ClientViewCatalog() {
  const isMounted = useIsMountedRef();
  const { merchantId } = useParams();
  const [reload, setReload] = useState(false);
  const [merchantName, setMerchantName] = useState();
  const [catalogs, setCatalogs] = useState([]);
  const [selectedCatalogId, setSelectedCatalogId] = useState(null);
  const [gettingCatalogs, setGettingCatalogs] = useState(false);

  const tableConfig = {
    collection: 'wholesale_products',
    query: {
      merchantId,
      catalogId: selectedCatalogId,
    },
    columns,
  };

  function getCatalogs() {
    return crudFactory.get({ collection: 'catalogs', query: { merchantId } }).then((_catalogs) => {
      isMounted.current && setCatalogs(_catalogs);

      if (!selectedCatalogId) {
        setSelectedCatalogId(_catalogs[0]?._id);
      }

      return _catalogs;
    });
  }

  function getMerchant() {
    return crudFactory.getById({ collection: 'merchants', id: merchantId }).then((merchant) => {
      isMounted.current && setMerchantName(merchant.name);
    });
  }

  useEffect(() => {
    if (merchantId) {
      setGettingCatalogs(true);
      getMerchant();
      getCatalogs().then(() => setGettingCatalogs(false));
    }
  }, [merchantId]);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    setReload(true);
  }, [selectedCatalogId]);

  if (!merchantId) {
    return '';
  }

  const ToolbarComponent = () => {
    return (
      <Box>
        <Box className="flex flex-row justify-end">
          <FormControl fullWidth size="small" variant="outlined" className="m-10 flex-1">
            <InputLabel id="catalog-selector">Catálogos</InputLabel>
            <Select
              labelId="catalog-selector"
              id="demo-simple-select"
              value={selectedCatalogId}
              label="Catálogos"
              onChange={(event) => setSelectedCatalogId(event.target.value)}
            >
              {catalogs.map((catalog) => (
                <MenuItem key={catalog._id} value={catalog._id}>
                  {catalog.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  };

  return (
    <FusePageCarded
      classes={{
        content: 'flex',
        contentCard: 'overflow-hidden',
      }}
      header={
        <PageHeader
          hideNavToogle
          title={<div className="ml-10">Catálogo al mayor {merchantName}</div>}
          icon="fas fa-boxes"
        />
      }
      content={
        <>
          {!catalogs.length && (
            <Box className="w-full h-full flex">
              {gettingCatalogs ? (
                <FuseLoading text="Cargando catálogos..." />
              ) : (
                <div className="flex flex-col justify-center items-center w-full bg-white">
                  <NotFound title={'Este comercio no tiene catálogos de venta al mayor'} />
                </div>
              )}
            </Box>
          )}

          {!!catalogs.length && selectedCatalogId && (
            <DataTable {...tableConfig} reload={reload} sortBy="name" ToolbarComponent={ToolbarComponent} />
          )}
        </>
      }
      innerScroll
    />
  );
}

export default ClientViewCatalog;
