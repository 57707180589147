import React from 'react';

const InventoryConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/store/employees',
      component: React.lazy(() => import('./Employees')),
    },
    // {
    //   path: '/store/prices-control/orders/:orderId',
    //   component: React.lazy(() => import('./order/Order')),
    // },
    // {
    // 	path: '/apps/prices-control',
    // 	component: () => <Redirect to="/apps/prices-control/" />
    // }
  ],
};

export default InventoryConfig;
