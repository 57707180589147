import HTTP from '../services/HTTP.service';

export function get(postData) {
  return HTTP.post({ url: `/crud/get`, postData }).then(({ result }) => result);
}

export function getById(postData) {
  return HTTP.post({ url: `/crud/getById`, postData }).then(({ result }) => result);
}

export function create(postData) {
  return HTTP.post({ url: `/crud/create`, postData }).then(({ result }) => result);
}

export function updateMany(postData) {
  return HTTP.post({ url: `/crud/updateMany`, postData }).then(({ result }) => result);
}

export function updateOne(postData) {
  return HTTP.post({ url: `/crud/updateOne`, postData }).then(({ result }) => result);
}

export function sendEmail(postData) {
  return HTTP.post({ url: `/crud/send-email`, postData }).then(({ result }) => result);
}

export function runAggregates(postData) {
  return HTTP.post({ url: `/crud/runAggregates`, postData }).then(({ result }) => result);
}

export function remove(postData) {
  return HTTP.post({ url: `/crud/remove`, postData }).then(({ result }) => result);
}
