export default ((env) => {
  console.log('ENV: ', process.env, env + ':8081/api/v1');
  
  if (env.startsWith('192')) {
    return {
      apiendpoint: () => 'http://' + env + ':8081/api/v1',
    };
  }

  return (
    {
      localhost: {
        apiendpoint: (version) => `http://localhost:8081/api/v1`,
      },
    }[env] || {
      apiendpoint: (version) => `/api/${version || 'v1'}`,
    }
  );
})(window.location.hostname);
