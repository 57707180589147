import HTTP from '../services/HTTP.service';

export function auth(postData) {
  return HTTP.post({ url: `/users/auth`, postData });
}

export function create(postData) {
  return HTTP.post({ url: `/users/new`, postData });
}

export function setPassword(userId, postData) {
  return HTTP.post({ url: `/users/${userId}/set-password`, postData });
}