import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as crudFactory from '../../../shared/factories/crud.factory';

export const getData = createAsyncThunk('datatableData/', ({ collection, query }) => {
  return crudFactory.get({ collection, query }).then((datatableData) => {
    return { datatableData };
  });
});

const initialState = { datatableData: [] };

const extraReducers = {
  [getData.pending]: () => ({ loading: true, data: [] }),
  [getData.rejected]: () => ({ loading: false, error: true }),
  [getData.fulfilled]: (state, { payload }) => {
    const { datatableData } = payload;

    if (!datatableData) {
      return { ...state, loading: false, error: 'datatableData-not-found' };
    }
    return { data: datatableData };
  },
};

const datatableSlice = createSlice({
  name: 'datatableData',
  initialState,
  reducers: {
    clear: (state) => {
      state.data = [];
    },
  },
  extraReducers,
});

export const { clear } = datatableSlice.actions;

export default datatableSlice.reducer;
