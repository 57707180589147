import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const paymentSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setPaymentPlan: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: {},
});

export const { setPaymentPlan } = paymentSlice.actions;

export default paymentSlice.reducer;
