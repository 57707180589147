import React from 'react';
import icons from './img';

export default function AppIcon({ icon, height, width, opacity, styles, fa, faSize, faColor, b64, className }) {
  if (!fa) {
    return (
      <img
        className={className}
        src={b64 ? icon : icons[icon]}
        style={{ height: height || 'auto', width: width || 'auto', opacity: opacity || 'auto', ...styles }}
        alt={'icon-' + icon}
      />
    );
  } else {
    return (
      <span
        className={fa + ' ' + className}
        style={{ fontSize: faSize || '12px', color: faColor || '', ...styles }}
      ></span>
    );
  }
}
