const es = {
  INVALID_PHONE: 'Teléfono inválido',
  INVALID_EMAIL: 'Correo inválido',
  REQUIRED: 'Requerido',
  PHONE: 'Teléfono',
  HTTP_ERROR_TITLE: 'Estamos teniendo inconvenientes.',
  HTTP_ERROR_MSG: 'Por favor, intente mas tarde.',
  CONSULTATION_SENT:
    'Su consulta ha sido enviada con exito. \nEstaremos atendiendo su solicitud a la brevedad posible.',
};

export default es;
