import axios from 'axios';
import EventEmitter from './EventEmitter.service';
import config from '../../../config';
import jwtParser from '../helpers/jwtParser';
//import cookiesService from './SessionService/cookies.service';

let HTTP = (function () {
  //defaults
  const axiosInstance = axios.create({
    baseURL: window.location.origin,
  });

  //Interceptors
  axiosInstance.interceptors.request.use((request) => {
    //token aqui
    let token = localStorage.ADMINLITE_TOKEN;

    if (token) {
      request.headers['user-id'] = jwtParser(token)._id;
    }
    return request;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.data?.userPlan) {
        EventEmitter.broadcast('setUserPlan', response.data?.userPlan);
      }

      return Promise.resolve(response.data);
    },
    (err) => {
      // if (err?.response?.status === 403) {
      //   window.location.href = '/login';
      // }
      // if (!err?.response?.config?.disableToasterOnError)
      EventEmitter.broadcast('httpError', err);

      if (err?.response.data?.userPlan) {
        EventEmitter.broadcast('setUserPlan', err.response.data?.userPlan);
      }

      console.log(err, err.message, err.msg);
      return Promise.reject(err.response ? err.response.data : err);
    }
  );

  const v1 = config.apiendpoint('v1');

  return {
    get: ({ url, apiEndpoint, disableToasterOnError = false, headers = {} }) => {
      EventEmitter.broadcast('httpStart');
      return axiosInstance
        .get(`${apiEndpoint || v1}${url}`, { disableToasterOnError, headers })
        .catch((err) => Promise.reject(err))
        .finally(() => EventEmitter.broadcast('httpEnd'));
    },
    post: ({ url, postData, apiEndpoint, disableToasterOnError = false }) => {
      EventEmitter.broadcast('httpStart');
      return axiosInstance
        .post(`${apiEndpoint || v1}${url}`, postData, { disableToasterOnError })
        .catch((err) => Promise.reject(err))
        .finally(() => EventEmitter.broadcast('httpEnd'));
    },
    put: ({ url, apiEndpoint, postData, disableToasterOnError }) => {
      EventEmitter.broadcast('httpStart');

      return axiosInstance
        .put(`${apiEndpoint || v1}${url}`, postData, { disableToasterOnError })
        .catch((err) => Promise.reject(err))
        .finally(() => EventEmitter.broadcast('httpEnd'));
    },
    delete: ({ url, apiEndpoint }) => {
      EventEmitter.broadcast('httpStart');

      return axiosInstance
        .delete(`${apiEndpoint || v1}${url}`)
        .catch((err) => Promise.reject(err))
        .finally(() => EventEmitter.broadcast('httpEnd'));
    },
  };
})();

export default HTTP;
