/* eslint-disable react-hooks/exhaustive-deps */
import FuseDialog from '@fuse/core/FuseDialog';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseSuspense from '@fuse/core/FuseSuspense';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from 'app/AppContext';
import SettingsPanel from 'app/fuse-layouts/shared-components/SettingsPanel';
import ExpiredInvoice from 'app/shared/components/ExpiredInvoice/ExpiredInvoice';
import InfoHeader from 'app/shared/components/InfoHeader/InfoHeader';
import NotFound from 'app/shared/components/NotFound/NotFound';
import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import { getOfficalRates } from '../../shared/factories/rates.factory';
import { setOfficialRates } from '../../main/store/slices/officialRates.slice';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '&.boxed': {
      maxWidth: 1280,
      margin: '0 auto',
      boxShadow: theme.shadows[3],
    },
    '&.scroll-body': {
      '& $wrapper': {
        height: 'auto',
        flex: '0 0 auto',
        overflow: 'auto',
      },
      '& $contentWrapper': {},
      '& $content': {},
    },
    '&.scroll-content': {
      '& $wrapper': {},
      '& $contentWrapper': {},
      '& $content': {},
    },
    '& .navigation': {
      '& .list-subheader-text, & .list-item-text, & .item-badge, & .arrow-icon': {
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    flex: '1 1 auto',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 3,
    overflow: 'hidden',
    flex: '1 1 auto',
  },
  content: {
    position: 'relative',
    display: 'flex',
    overflow: 'auto',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    '-webkit-overflow-scrolling': 'touch',
    zIndex: 2,
  },
}));

function Layout1(props) {
  const { config, merchant, payment } = useSelector(
    ({ fuse, merchant, payment }) => ({
      config: fuse.settings.current.layout.config,
      merchant,
      payment,
    }),
    shallowEqual
  );

  const appContext = useContext(AppContext);
  const classes = useStyles(props);
  const { routes } = appContext;
  const dispatch = useDispatch();

  useEffect(() => {
    getOfficalRates()
      .then((fetchedRates) => {
        dispatch(setOfficialRates(fetchedRates));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  switch (config.scroll) {
    case 'body': {
      return (
        <div id="fuse-layout" className={clsx(classes.root, config.mode, `scroll-${config.scroll}`)}>
          {config.leftSidePanel.display && <LeftSideLayout1 />}

          <div className="flex flex-1 flex-col overflow-hidden relative">
            {/* {config.toolbar.display && config.toolbar.style === 'fixed' && config.toolbar.position === 'above' && (
              <ToolbarLayout1 />
            )} */}

            <FuseScrollbars className="overflow-auto" scrollToTopOnRouteChange>
              {/* {config.toolbar.display && config.toolbar.style !== 'fixed' && config.toolbar.position === 'above' && (
                <ToolbarLayout1 />
              )} */}

              <div className={classes.wrapper}>
                {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

                <div className={classes.contentWrapper}>
                  {/* {config.toolbar.display && config.toolbar.position === 'below' && <ToolbarLayout1 />} */}

                  <div className={classes.content}>
                    <FuseDialog />

                    <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

                    {props.children}
                  </div>

                  {config.footer.display && config.footer.position === 'below' && <FooterLayout1 />}

                  <SettingsPanel />
                </div>

                {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
              </div>

              {config.footer.display && config.footer.style !== 'fixed' && config.footer.position === 'above' && (
                <FooterLayout1 />
              )}
            </FuseScrollbars>

            {config.footer.display && config.footer.style === 'fixed' && config.footer.position === 'above' && (
              <FooterLayout1 />
            )}
          </div>

          {config.rightSidePanel.display && <RightSideLayout1 />}

          <FuseMessage />
        </div>
      );
    }
    case 'content':
    default: {
      return (
        <div id="fuse-layout" className={clsx(classes.root, config.mode, `scroll-${config.scroll}`)}>
          {config.leftSidePanel.display && <LeftSideLayout1 />}

          <div className="flex flex-1 flex-col overflow-hidden relative">
            {/* {config.toolbar.display && config.toolbar.position === 'above' && <ToolbarLayout1 />} */}

            {!merchant?.loading && !merchant?.error && payment?.status !== 'expired' && (
              <div className={classes.wrapper}>
                {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

                <div className={classes.contentWrapper}>
                  {/* {config.toolbar.display &&
                    config.toolbar.position === 'below' &&
                    config.toolbar.style === 'fixed' && <ToolbarLayout1 />} */}

                  <FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
                    {/* {config.toolbar.display &&
                      config.toolbar.position === 'below' &&
                      config.toolbar.style !== 'fixed' && <ToolbarLayout1 />} */}

                    <FuseDialog />
                    <InfoHeader />
                    <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

                    {props.children}

                    {config.footer.display && config.footer.position === 'below' && config.footer.style !== 'fixed' && (
                      <FooterLayout1 />
                    )}
                  </FuseScrollbars>

                  {config.footer.display && config.footer.position === 'below' && config.footer.style === 'fixed' && (
                    <FooterLayout1 />
                  )}

                  {/* <SettingsPanel /> */}
                </div>

                {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
              </div>
            )}

            {merchant?.loading && (
              <Box width="320px" m="auto">
                <FuseLoading text={merchant?.loading ? 'Obteniendo datos del comercio...' : 'Cargando...'} />
              </Box>
            )}

            {payment?.status === 'expired' && (
              <div className="flex flex-col justify-center items-center h-full">
                <ExpiredInvoice />
              </div>
            )}

            {merchant?.error && payment?.status !== 'expired' && (
              <div className="flex flex-col justify-center items-center h-full">
                <NotFound title={`No pudimos obtener los datos del comercio.`} message="Intenta recargar la página." />
                <Button variant="outlined" onClick={() => window.location.reload()} className="normal-case">
                  Recargar
                </Button>
              </div>
            )}

            {config.footer.display && config.footer.position === 'above' && <FooterLayout1 />}
          </div>

          {config.rightSidePanel.display && <RightSideLayout1 />}

          <FuseMessage />
        </div>
      );
    }
  }
}

export default React.memo(Layout1);
