import React from 'react';
import { Typography } from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';

function NotFound({ title, message }) {
  return (
    <div className='flex flex-col items-center justify-center p-16'>
      <div className='max-w-640 text-center'>
        <FuseAnimate animation='transition.expandIn' delay={100}>
          <Typography variant='h5' color='inherit' className='font-medium mb-16'>
            {title}
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <Typography variant='h6' color='textSecondary' className='mb-16'>
            {message}
          </Typography>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default NotFound;
