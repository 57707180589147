import { createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { merchantLogout } from 'app/main/store/slices/merchant.slice';

export const setUserData = (user) => async (dispatch) => {
  /*
        You can redirect the logged-in user to a specific route depending on his role
         */

  history.location.state = {
    redirectUrl: '/store/prices-control/products', // for example 'apps/academy'
  };

  /*
    Set User Settings
     */
  //dispatch(setDefaultSettings(user.data.settings));

  dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  //dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  localStorage.removeItem('ADMINLITE_TOKEN');
  history.push({
    pathname: '/login',
  });

  //jwtService.logout();

  dispatch(setInitialSettings());

  dispatch(userLoggedOut());
  dispatch(merchantLogout());
};

const initialState = {};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => ({ ...state, ...action.payload }),
    userLoggedOut: () => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
