import FuseAnimate from '@fuse/core/FuseAnimate';
import Button from '@material-ui/core/Button';
import useWindowSize from 'app/shared/hooks/useWindowSize';
import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import Hidden from '@material-ui/core/Hidden';

function PageHeader({ title, buttonConfig, description, rightHeader, hideNavToogle }) {
  const { xs } = useWindowSize();
  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
        {!hideNavToogle && (
          <Hidden lgUp>
            <NavbarMobileToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
          </Hidden>
        )}
        <Typography variant="h6" className="text-18">
          {title}
        </Typography>
        {description}
      </div>

      {buttonConfig && (
        <FuseAnimate animation="transition.slideRightIn" delay={300}>
          <Button
            disabled={buttonConfig.disabled}
            to="/apps/e-commerce/products/new"
            className={clsx({ 'whitespace-no-wrap normal-case': true, 'p-2 px-6': xs })}
            variant="contained"
            color="secondary"
            onClick={buttonConfig.onClick}
          >
            <span className="flex">{buttonConfig.title}</span>
          </Button>
        </FuseAnimate>
      )}

      {rightHeader && <div className="flex items-center">{rightHeader}</div>}
    </div>
  );
}

export default PageHeader;
