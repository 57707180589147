import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as crudFactory from '../../../shared/factories/crud.factory';

export const getMerchant = createAsyncThunk('merchant/', (id) => {
  return crudFactory.getById({ collection: 'merchants', id }).then((merchant) => {
    return { merchant };
  });
});

const initialState = null;

const extraReducers = {
  [getMerchant.pending]: () => ({ loading: true }),
  [getMerchant.rejected]: () => ({ loading: false, error: true }),
  [getMerchant.fulfilled]: (state, { payload }) => {
    const { merchant } = payload;

    if (!merchant) {
      return { ...state, loading: false, error: 'merchant-not-found' };
    }
    return merchant;
  },
};

const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  extraReducers,
  reducers: {
    merchantLogout: () => initialState,
  },
});
export const { merchantLogout } = merchantSlice.actions;

export default merchantSlice.reducer;
