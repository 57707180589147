import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const officalRatesSlice = createSlice({
  name: 'officalRates',
  initialState,
  reducers: {
    setOfficialRates: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: {},
});

export const { setOfficialRates } = officalRatesSlice.actions;

export default officalRatesSlice.reducer;
