import React from 'react';

const CatalogConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/store/catalog',
      component: React.lazy(() => import('./Catalog')),
    },
  ],
};

export default CatalogConfig;
