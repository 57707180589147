/* eslint-disable react-hooks/exhaustive-deps */
import { TextFieldFormsy } from '@fuse/core/formsy';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { restore, submitRegister } from 'app/auth/store/registerSlice';
import { useHistory } from 'react-router';
import SubmitButton from 'app/shared/components/SubmitButton/SubmitButton';
import { showMessage } from 'app/store/fuse/messageSlice';

function JWTRegisterTab() {
  const history = useHistory();
  const dispatch = useDispatch();
  const register = useSelector(({ auth }) => auth.register);

  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (register.error) {
      setLoading(false);
    }
  }, [register]);

  useEffect(() => {
    if (register.success) {
      dispatch(showMessage({ message: 'Registro exitoso', variant: 'success' }));
      history.push('/login');
      dispatch(restore());
    }
  }, [register.success]);

  function disableButton() {
    setIsFormValid(false);
  }

  function enableButton() {
    setIsFormValid(true);
  }

  function handleSubmit(model) {
    model.merchant.idNumber = 'J-' + model.merchant.idNumber;
    model.email = model.email.toLowerCase()
    setLoading(true);
    dispatch(submitRegister(model));
  }

  return (
    <div className='w-full'>
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className='flex flex-col justify-center w-full'
      >
        <TextFieldFormsy
          size='small'
          className='mb-16'
          type='text'
          name='merchant.name'
          label='Nombre de la empresa'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  person
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        <TextFieldFormsy
          size='small'
          className='mb-16'
          type='text'
          name='merchant.idNumber'
          label='RIF o Cédula'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  person
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        <TextFieldFormsy
          size='small'
          className='mb-16'
          type='text'
          name='fullname'
          label='Tu nombre'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  person
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        <TextFieldFormsy
          size='small'
          className='mb-16'
          type='text'
          name='email'
          label='Tu correo'
          validations={{
            isEmail: true,
          }}
          validationErrors={{
            isEmail: 'Formato de correo incorrecto.',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  email
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        <TextFieldFormsy
          size='small'
          className='mb-16'
          type='password'
          name='password'
          label='Contraseña'
          validations='equalsField:password-confirm'
          validationErrors={{
            equalsField: 'Debe coincidir con la confirmación',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  vpn_key
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        <TextFieldFormsy
          size='small'
          className='mb-16'
          type='password'
          name='password-confirm'
          label='Confirmación de contraseña'
          validations='equalsField:password'
          validationErrors={{
            equalsField: 'Contraseñas no coinciden',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon className='text-20' color='action'>
                  vpn_key
                </Icon>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          required
        />

        {/* <TextFieldFormsy
          size='small'
          className='mb-16'
          type='text'
          name='referedFrom'
          label='Código de referido (opcional)'
          variant='outlined'
        /> */}

        <SubmitButton label='Registrarse' disabled={!isFormValid} loading={loading} />
      </Formsy>
    </div>
  );
}

export default JWTRegisterTab;
