import React from 'react';

const SuppliersConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/store/suppliers',
      exact: true,
      component: React.lazy(() => import('./Suppliers')),
    },
    {
      path: '/store/suppliers/:supplierId',
      component: React.lazy(() => import('./supplier/SupplierTransactions')),
    },
  ],
};

export default SuppliersConfig;
