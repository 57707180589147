import { authRoles } from 'app/auth';
import ClientViewCatalog from './ClientViewCatalog'

const ClientViewCatalogConfig = {
  settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/:merchantId/client-view-catalog',
			component: ClientViewCatalog
		}
  ],
};

export default ClientViewCatalogConfig;
